const checkCanUseServiceWorker = () => {
  return 'serviceWorker' in navigator;
};

const unregister = async () => {
  if (!checkCanUseServiceWorker()) {
    return;
  }
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration) {
    return;
  }
  await registration.unregister();
};

export default {
  unregister,
};
